import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  MARK_BOLD,
  MARK_ITALIC,
  NODE_HEADING,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";

import { Body } from "@/components/atoms";
import type { BodyStoryblok } from "@/types/storyblok-blok-types";

import Button from "../Button/Button";
import styles from "./body.module.css";
import BodyHeading from "./resolvedBloks/BodyHeading";
import Italic from "./resolvedBloks/Italic";
import ListItem from "./resolvedBloks/ListItem";

const UI = ({ blok }: { blok: BodyStoryblok }) => {
  const { body } = blok;

  const renderOptions: {
    markResolvers: Record<string, (children: JSX.Element) => JSX.Element>;
    blokResolvers: Record<string, (blok: SbBlokData) => JSX.Element>;
    nodeResolvers: Record<string, (children: any, level: any) => JSX.Element>;
  } = {
    markResolvers: {
      [MARK_BOLD]: (children) => <strong>{children}</strong>,
      [MARK_ITALIC]: (children) => <Italic>{children}</Italic>,
    },
    blokResolvers: {
      ["button"]: (blok: any) => (<Button blok={blok} />) as JSX.Element,

      // ["title"]: (blok) => <StoryblokComponent blok={blok} />,
      // ["media"]: (blok) => <StoryblokComponent blok={blok} />,
      // ["mediaGallery"]: (blok) => <StoryblokComponent blok={blok} />,
      // ["link"]: (blok) => (
      //   <StoryblokLink
      //     blok={blok as StoryblokLinkBlok}
      //     className={`${styles.link}`}
      //   />
      // ),
      // ["space"]: () => <div className={`${styles.space}`} />,
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, level) => (
        <BodyHeading level={level.level}>{children}</BodyHeading>
      ),
      [NODE_PARAGRAPH]: (children) => (
        <Body as="p" styledAs="b2">
          {children}
        </Body>
      ),
      [NODE_UL]: (children) => (
        <ul className={styles.unordered__list}>{children}</ul>
      ),
      [NODE_OL]: (children) => (
        <ol className={styles.ordered__list}>{children}</ol>
      ),
      [NODE_LI]: (children) => <ListItem>{children}</ListItem>,
    },
  };
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.container,
        blok.align === "center" && clsx(styles.container__center, "center"),
        blok.align === "right" && clsx(styles.container__right, "right"),
        blok.align === "left" && clsx(styles.container__left, "left"),
        "body-blok"
      )}
      data-blok
    >
      {render(body, renderOptions)}
    </div>
  );
};

export default UI;

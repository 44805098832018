import * as NavMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import Link from "next/link";

import getLink from "@/lib/storyblok/utils/getLink";
import type { MenuStoryblok } from "@/types/storyblok-blok-types";

import styles from "./header-bloks.module.css";
import MenuSection from "./MenuSection";

const UI = ({
  menu,
  link,
}: {
  menu: MenuStoryblok;
  link?: string | undefined;
}) => {
  return (
    <NavMenu.Item>
      {link ? (
        <NavMenu.Trigger className={clsx(styles.menuButton)}>
          <Link
            href={link}
            className={styles.menu__button__link}
            tabIndex={-1}
            onKeyDownCapture={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            {menu.title}
            <div className={styles.underline} />
          </Link>
        </NavMenu.Trigger>
      ) : (
        <NavMenu.Trigger className={clsx(styles.menuButton)}>
          {menu.title}
          <div className={styles.underline} />
        </NavMenu.Trigger>
      )}
      <NavMenu.Content className={styles.menuContent}>
        <div className={styles.linkMenus}>
          {menu.section &&
            menu.section.map((section) => (
              <MenuSection {...section} key={section._uid} />
            ))}
        </div>
      </NavMenu.Content>
    </NavMenu.Item>
  );
};

const MenuItem = (menu: MenuStoryblok) => {
  const link = getLink(menu?.link);
  return <UI menu={menu} link={link} />;
};

export default MenuItem;

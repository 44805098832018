import { Heading } from "@/components/atoms";

interface HeadingProps {
  children: React.ReactNode;
  level: number;
}

const BodyHeading = ({ children, level }: HeadingProps) => {
  if (level === 1) {
    return (
      <Heading as="h2" styledAs="t1">
        {children}
      </Heading>
    );
  }
  if (level === 2) {
    return (
      <Heading as="h3" styledAs="t2">
        {children}
      </Heading>
    );
  }
  if (level === 3) {
    return (
      <Heading as="h4" styledAs="t3">
        {children}
      </Heading>
    );
  }
  if (level === 4) {
    return (
      <Heading as="h5" styledAs="t4">
        {children}{" "}
      </Heading>
    );
  }

  return (
    <Heading as="h2" styledAs="t1">
      {children}
    </Heading>
  );
};

export default BodyHeading;

import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";
import clsx from "clsx";

import BlokWrap from "@/components/atoms/BlokWrap";
import NewsletterInput from "@/components/atoms/NewsletterInput";

import { NewsletterSignupStoryblok } from "@/types/storyblok-blok-types";
import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./newslettersignup.module.css";

const NewsletterSignup = ({ blok }: { blok: NewsletterSignupStoryblok }) => {
  return (
    <BlokWrap
      animated="custom"
      className={styles.container}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      {blok.preamble && (
        <h2 className={clsx("preamble", styles["text-center"])}>
          {blok.preamble}
        </h2>
      )}
      {blok.title && (
        <p className={clsx("uppercase h4", styles["text-center"])}>
          {blok.title}
        </p>
      )}
      {blok.description && blok.description.length > 0 && (
        <StoryblokComponent blok={blok.description[0]} />
      )}
      <NewsletterInput className={styles.input} />
    </BlokWrap>
  );
};

export default NewsletterSignup;
